<template>
  <PublicLayout>
    <div class="top">
      <p>{{topic}}</p>
    </div>
    <div class="center" v-html="content">
      
    </div>
  </PublicLayout>
</template>
<script>
// @ is an alias to /src
import PublicLayout from '@/components/PublicLayout.vue'

export default {
  name: 'About',
  components: {
    PublicLayout
  },
  data(){
    return{
      content: null,
      topic: 'Privacy Policy'
    }
  },
  async created(){
    let key = 'prvacypolicy';
    const res = await this.axios.get(`/base/getArticle/${key}`)
    this.content = res.data.content;
  }
}
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
  @include flex(center, center);
  font-size: 50px;
  color: #fff;
  font-family: SFProDisplay;
  background: url(../assets/images/about_us/pic1.png) center center no-repeat;
  background-size: 100% 100%;
  &::after {
    content: "";
    display: block;
    padding-bottom: 19%;
  }
}
.center {
  > .title {
    font-size: 50px;
    font-family: SFProDisplay;
    color: #000;
    margin-top: 109px;
    margin-bottom: 21px;
  }
  .text {
    color: #000;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .concat {
    padding-bottom: 150px;
    margin-top: 74px;
    .phone,
    .email {
      color: #000;
      @include flex(center, flex-start);
      margin-left: 0;
      img {
        margin-right: 17px;
      }
    }
    .email {
      margin-top: 14px;
    }
  }

}
</style>
<style scoped>
  .center >>> *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .center >>> p, .center >>> h1, .center >>> h2, .center >>> h3, .center >>> h4, .center >>> h5, .center >>> table, .center >>> pre{
    margin: 10px 0;
    line-height: 1.5;
  }

  .center >>> h1{
    font-size: 32px !important;
  }

  .center >>> p{
    font-size: 16px !important;
  }

  .center >>> ul, .center >>> ol{
    margin: 10px 0 10px 20px;
  }

  .center >>> li{
    list-style-type: disc;
  }

  .center >>> ol{
    list-style-type: decimal;
  }
  .center >>> blockquote {
    display: block;
    border-left: 8px solid #d0e5f2;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
  }
  .center >>> hr {
    cursor: pointer;
    display: block;
    height: 0px;
    border: 0;
    border-top: 3px solid #ccc;
    margin: 20px 0;
  }
  .center >>> table {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  .center >>> table th {
    border-bottom: 2px solid #ccc;
    text-align: center;
    background-color: #f1f1f1;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
    min-height: 30px;
    height: 30px;
  }
  .center >>> table td{
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
    min-height: 30px;
    height: 30px;
  }
</style>
